import fieldNames from './field-names';
import fieldHelper from './field-helper';
import {
  isValidEmail,
  isValidName,
  isValidTelephoneNumber,
} from '../../utils/validation-utils';

const validationHelper = {
  getValidatorFunc: getValidatorFunc,
};

function getValidatorFunc(namesOfFieldsOnForm) {
  return formValues => {
    let errors = {};

    if (fieldHelper.isFieldUsed(fieldNames.FIRST_NAME, namesOfFieldsOnForm)) {
      if (!formValues.firstName) {
        errors.firstName = 'First name is required';
      } else if (formValues.firstName.length > 15) {
        errors.firstName = 'Must be 15 characters or less';
      } else if (!isValidName(formValues.firstName)) {
        errors.firstName = 'Please enter a valid name';
      }
    }

    if (fieldHelper.isFieldUsed(fieldNames.LAST_NAME, namesOfFieldsOnForm)) {
      if (!formValues.lastName) {
        errors.lastName = 'Last name is required';
      } else if (formValues.lastName.length > 20) {
        errors.lastName = 'Must be 20 characters or less';
      } else if (!isValidName(formValues.lastName)) {
        errors.lastName = 'Please enter a valid name';
      }
    }

    if (fieldHelper.isFieldUsed(fieldNames.EMAIL, namesOfFieldsOnForm)) {
      if (!formValues.email) {
        errors.email = 'Required';
      } else if (!isValidEmail(formValues.email)) {
        errors.email = 'Invalid email address';
      }
    }

    if (fieldHelper.isFieldUsed(fieldNames.PHONE_NUMBER, namesOfFieldsOnForm)) {
      if (!formValues.phoneNumber) {
        errors.phoneNumber = 'Required';
      } else if (!isValidTelephoneNumber(formValues.phoneNumber)) {
        errors.phoneNumber = 'Invalid phone number';
      }
    }

    if (fieldHelper.isFieldUsed(fieldNames.PROFESSION, namesOfFieldsOnForm)) {
      if (!formValues.profession) {
        errors.profession = 'Required';
      }
    }

    if (fieldHelper.isFieldUsed(fieldNames.SPECIALTY, namesOfFieldsOnForm)) {
      if (!formValues.specialty) {
        errors.specialty = 'Required';
      }
    }

    if (
      fieldHelper.isFieldUsed(
        fieldNames.HOW_DID_YOU_FIND_US,
        namesOfFieldsOnForm
      )
    ) {
      if (!formValues.howDidYouFindUs) {
        errors.howDidYouFindUs = 'Required';
      }
    }

    if (fieldHelper.isFieldUsed(fieldNames.REFERRER, namesOfFieldsOnForm)) {
      if (
        formValues.howDidYouFindUs === 'Referred by someone' &&
        !formValues.referrer
      ) {
        errors.referrer = 'Required';
      }
    }

    if (fieldHelper.isFieldUsed(fieldNames.OTHER, namesOfFieldsOnForm)) {
      if (formValues.howDidYouFindUs === 'Other') {
        if (!formValues.other) errors.other = 'Required';
        else if (formValues.other.length > 100)
          errors.other = 'Please use less than 100 characters';
      }
    }

    return errors;
  };
}

export default validationHelper;
