import states from '../data/states';
import constants from '../constants/other';

export function useJobFiltersService() {
  const isValidJobLocation = unreliableLocation => {
    return (
      unreliableLocation &&
      unreliableLocation !== constants.DEFAULT_SELECT_VALUE &&
      states.some(
        state =>
          state.value.toLocaleLowerCase() ===
          unreliableLocation.toLocaleLowerCase()
      )
    );
  };

  const isValidJobSpecialty = (unreliableSpecialty, specialtiesList) => {
    return (
      !!unreliableSpecialty &&
      !!specialtiesList &&
      unreliableSpecialty !== constants.DEFAULT_SELECT_VALUE &&
      specialtiesList.some(
        specialty => specialty.value.toString() === unreliableSpecialty
      )
    );
  };

  return { isValidJobLocation, isValidJobSpecialty };
}
