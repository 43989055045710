import React, { useEffect } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

const PopupNotification = ({
  message,
  onClose,
  duration,
  notificationStyleClass,
  showCloseIcon,
}) => {
  useEffect(() => {
    if (duration === 'persistent') {
      return;
    }
    setTimeout(onClose, getPopupLength());
  }, []);

  const getPopupLength = () => {
    let length;
    switch (duration) {
      case 'short':
        length = 3000;
        break;
      case 'medium':
        length = 6000;
        break;
      case 'long':
        length = 10000;
        break;
      default:
        length = 6;
        break;
    }
    return length;
  };

  const getPopupClassType = () => {
    let fadeClass;
    switch (duration) {
      case 'short':
        fadeClass = styles.shortFade;
        break;
      case 'medium':
        fadeClass = styles.mediumFade;
        break;
      case 'long':
        fadeClass = styles.longFade;
        break;
      case 'persistent':
        fadeClass = '';
        break;
      default:
        fadeClass = styles.mediumFade;
    }
    return fadeClass;
  };

  return (
    <div
      className={`notification ${notificationStyleClass} ${getPopupClassType()} ${
        styles.container
      } ${styles.notification}`}
    >
      {showCloseIcon && (
        <button
          className={`delete`}
          aria-label="Close"
          type="button"
          onClick={() => onClose()}
        ></button>
      )}
      {message}
    </div>
  );
};

PopupNotification.propTypes = {
  duration: PropTypes.oneOf(['short', 'medium', 'long', 'persistent']),
  onClose: PropTypes.func,
  message: PropTypes.string.isRequired,
  notificationStyleClass: PropTypes.string,
  showCloseIcon: PropTypes.bool,
};

PopupNotification.defaultProps = {
  duration: 'medium',
  notificationStyleClass: 'is-success',
  showCloseIcon: true,
  onClose: () => {},
};

export default PopupNotification;
