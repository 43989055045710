const fieldNames = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  PROFESSION: 'profession',
  SPECIALTY: 'specialty',
  OTHER_PROFESSION: 'otherProfession',
  HOW_DID_YOU_FIND_US: 'howDidYouFindUs',
  REFERRER: 'referrer',
  OTHER: 'other',
};

export default fieldNames;
