import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './select.module.scss';

/* eslint-disable jsx-a11y/no-onchange */

const Select = ({
  options,
  onChange,
  onBlur,
  placeholder,
  value,
  icon,
  rounded,
  selectProps,
  errorMessage,
  errorClass,
  showDisabled,
  id,
  name,
  showLabel,
}) => {
  const getPlaceholder = () => {
    return placeholder !== null ? (
      <option value="" disabled>
        {placeholder}
      </option>
    ) : null;
  };

  const getErrorMessage = errorMessage => {
    if (errorMessage === null) {
      return null;
    }

    return <p className={`help ${errorClass}`}>{errorMessage}</p>;
  };

  const getIsDisabled = () => {
    return value === '' && showDisabled;
  };

  return (
    <>
      <label className={`label ${showLabel ? '' : 'is-sr-only'}`} htmlFor={id}>
        {placeholder}
      </label>
      <div
        className={`control ${styles.selectControl} ${
          icon !== null ? 'has-icons-left' : ''
        }`}
      >
        <div
          className={`select ${rounded ? 'is-rounded' : ''} ${
            styles.selectWrapper
          }`}
        >
          <select
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            id={id}
            className={`${styles.select} ${
              getIsDisabled() ? styles.isDisabled : ''
            }`}
            {...selectProps}
          >
            {getPlaceholder()}
            {options.map((option, i) => (
              <option value={option.value} key={i} className={styles.option}>
                {option.name}
              </option>
            ))}
          </select>
          {icon !== null ? (
            <span className="icon is-left">
              <FontAwesomeIcon icon={icon} />
            </span>
          ) : null}
        </div>
      </div>
      {getErrorMessage(errorMessage)}
    </>
  );
};

export default Select;

Select.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.object,
};

Select.defaultProps = {
  placeholder: null,
  icon: null,
  rounded: false,
  selectProps: {},
  onChange: () => {},
  onBlur: () => {},
  errorClass: '',
  value: '',
  showDisabled: false, // if true select menu has greyed out text before selection
  name: '',
  showLabel: false, // by default use placeholder as label and make label screen reader only
  id: '',
};
