import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Img from 'gatsby-image';

import styles from './jobs-list-item.module.scss';
import { getJobPath } from '../../../utils/url-helpers';
import { getFormattedLocation } from '../../../utils/format-helpers';

const JobListItem = ({
  id,
  specialtyName,
  city,
  state,
  durationInWeeks,
  shiftType,
  professionDescription,
  queryData,
  jobBoxClass,
}) => {
  const getImgEdgeContains = str => {
    return queryData.allImageSharp.edges.find(e =>
      e.node.fluid.originalName.includes(str)
    );
  };

  const getStateImgEdge = stateAbbreviation => {
    if (stateAbbreviation === null) {
      // if job doesn't have a state use the default image
      return getImgEdgeContains('default');
    }

    // return images for a given state or default if not found
    return (
      getImgEdgeContains(stateAbbreviation) || getImgEdgeContains('default')
    );
  };

  return (
    <div className={`box ${styles.jobBox} ${jobBoxClass}`}>
      <div className={styles.jobInfo}>
        <p className={`title is-5 has-text-weight-bold`}>{specialtyName}</p>
        <p className={`subtitle is-6 ${styles.jobLocation}`}>
          {getFormattedLocation(city, state)}
        </p>
        <p className="is-size-7 has-text-grey-dark">
          {shiftType && (
            <>
              <span className={`${styles.shift}`}>{shiftType}</span>•
            </>
          )}
          <span className={`${styles.duration}`}>
            {durationInWeeks} week{durationInWeeks !== '1' ? 's' : ''}
          </span>
        </p>
        <div className={`buttons are-small ${styles.actionButtons}`}>
          <Link
            to={getJobPath({
              id,
              specialtyName,
              city,
              state,
              professionDescription,
            })}
            className="button is-primary"
          >
            View
          </Link>
        </div>
      </div>
      <Link
        to={getJobPath({
          id,
          specialtyName,
          city,
          state,
          professionDescription,
        })}
        className={styles.imgContainer}
      >
        <>
          <span className="is-sr-only">View {specialtyName}</span>
          <div className={styles.imgContainerInner}>
            <Img
              className={`${styles.imgWrapper}`}
              fluid={getStateImgEdge(state.abbreviation).node.fluid}
            />
          </div>
        </>
      </Link>
    </div>
  );
};

JobListItem.defaultProps = {
  jobBoxClass: '',
};

JobListItem.propTypes = {
  specialtyName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.object,
  durationInWeeks: PropTypes.string,
  shiftType: PropTypes.string,
  jobBoxClass: PropTypes.string,
};

export default JobListItem;
