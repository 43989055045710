import formLocations from './form-locations';

export function getSubmitButtonText(formLocation) {
  let text = '';

  switch (formLocation) {
    case formLocations.JOB_DETAILS:
      text = 'Apply Now';
      break;
    case formLocations.HOME_PAGE:
      text = 'Join for free';
      break;
    default:
      text = 'Submit';
  }

  return text;
}

export const getSubmitButtonClass = (job = null, formLocation) => {
  if (job && job.statusName === 'Closed') {
    return 'is-primary';
  }

  return 'is-info';
};
