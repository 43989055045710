import leadSources from '../../constants/lead-sources';
import preSetFieldLists from './pre-set-field-lists';

const fieldHelper = {
  isFieldUsed: isFieldUsed,
  getFormFields: getFormFields,
  getInitialFieldValues: getInitialFieldValues,
  getLeadSourceNames: getLeadSourceNames,
};

function isFieldUsed(fieldName, formFieldNames) {
  return formFieldNames.some(f => f === fieldName);
}

function getFormFields(formLocation) {
  return formLocation
    ? preSetFieldLists[formLocation]
    : preSetFieldLists.DEFAULT;
}

function getInitialFieldValues(defaultFields) {
  let initialValues = {};
  defaultFields.forEach(def => {
    initialValues[def] = '';
  });

  return initialValues;
}

function getLeadSourceNames() {
  let leadSourceNames = leadSources.map(l => {
    return {
      name: l,
      value: l,
    };
  });

  return leadSourceNames;
}

export default fieldHelper;
