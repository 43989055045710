import React, { useState, useEffect } from 'react';
import { faSearch, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import Select from '../form-components/select';
import states from '../../data/states';
import specialtiesService from '../../services/specialties-service';
import styles from './jobs-search.module.scss';
import constants from '../../constants/other';
import { useLocation } from '@reach/router';
import { urlParams } from '../../constants/url-params';
import { useJobFiltersService } from '../../services/jobs-filters-service';
import { useAuth } from '../../providers/auth-provider';

const JobsSearch = ({ onSubmit, isHorizontal }) => {
  const location = useLocation();
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  const { isValidJobLocation, isValidJobSpecialty } = useJobFiltersService();
  const { meta } = useAuth();

  useEffect(() => {
    const url = new URL(location.href);
    const params = url.searchParams;
    const jobSpecialty = params.get(urlParams.SPECIALTY);
    const jobLocation = params.get(urlParams.LOCATION);

    if (isValidJobLocation(jobLocation)) {
      setSelectedLocation(currentValue =>
        currentValue === jobLocation ? currentValue : jobLocation.toUpperCase()
      );
    }

    if (specialties) {
      if (isValidJobSpecialty(jobSpecialty, specialties)) {
        setSelectedSpecialty(currentValue =>
          currentValue === jobSpecialty ? currentValue : jobSpecialty
        );
      }
    }
  }, [location.href, specialties]);

  const handleSpecialtyChange = e => {
    setSelectedSpecialty(e.target.value);
  };

  const handleLocationChange = e => {
    setSelectedLocation(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(selectedSpecialty, selectedLocation);
  };

  const formatSpecialtyOptions = (data, isUsingBullhornCrm) => {
    const formatted = isUsingBullhornCrm
      ? data.map(entry => {
          return { name: entry, value: entry };
        })
      : specialtiesService.formatForSelectElement(data);

    return formatted;
  };

  useEffect(() => {
    if (meta.shouldUseBullhorn) {
      specialtiesService.getBullhornSpecialtyNames().then(({ data }) => {
        const formatted = formatSpecialtyOptions(data, meta.shouldUseBullhorn);
        setSpecialties(formatted);
      });
    } else {
      specialtiesService
        .getSpecialtiesWithJobsAvailableNow()
        .then(({ data }) => {
          const formatted = formatSpecialtyOptions(
            data,
            meta.shouldUseBullhorn
          );
          setSpecialties(formatted);
        });
    }

    if (!states.some(s => s.value === constants.DEFAULT_SELECT_VALUE)) {
      states.unshift({
        name: 'All states',
        value: constants.DEFAULT_SELECT_VALUE,
      });
    }
  }, [specialtiesService]);

  return (
    <form
      className={`${isHorizontal ? styles.isHorizontal : ''}`}
      onSubmit={handleSubmit}
    >
      <div className={`field ${styles.selectField}`}>
        <Select
          options={specialties}
          onChange={handleSpecialtyChange}
          placeholder="Specialty"
          name="Specialty"
          id="jobs-search-specialty"
          value={selectedSpecialty}
          icon={faSearch}
          rounded
        />
      </div>
      <div className={`field ${styles.selectField}`}>
        <Select
          options={states}
          onChange={handleLocationChange}
          placeholder="Location"
          name="Location"
          id="jobs-search-location"
          value={selectedLocation}
          icon={faMapMarkerAlt}
          rounded
        />
      </div>
      <div className={`field ${styles.searchJobsWrapper}`}>
        <input
          className={`button is-primary ${styles.searchJobsBtn}`}
          type="submit"
          value="Search Jobs"
          id="jobs-search-submit"
        />
      </div>
    </form>
  );
};

JobsSearch.defaultProps = {
  isHorizontal: false,
};

export default JobsSearch;
