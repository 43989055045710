import apiService from './api-service';

export function useJobsApi() {
  const endpoint = '/api/jobs';
  return {
    fetchJobs: function (filters) {
      return apiService.get(endpoint, filters);
    },

    getSimliarJobs: function (jobId) {
      return apiService.get(`${endpoint}/similar/${jobId}`);
    },

    getJobDetails: function (jobId) {
      return apiService.get(`${endpoint}/${jobId}`);
    },
  };
}
