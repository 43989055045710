import React from 'react';
import PropTypes from 'prop-types';

const ApiErrorMessage = ({ errorMsg, errorAddendum }) => {
  return (
    <p className="notification is-danger">
      {errorMsg} {` `} {errorAddendum}
    </p>
  );
};

export default ApiErrorMessage;

ApiErrorMessage.propTypes = {
  errorMsg: PropTypes.string.isRequired,
  errorAddendum: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ApiErrorMessage.defaultProps = {
  errorAddendum: (
    <>
      Please try again or
      {` `}
      <a href="/contact-us/">contact us</a> if the problem persists.
    </>
  ),
};
