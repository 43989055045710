import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import apiService from '../../services/api-service';
import { useJobApplicationsApi } from '../../services/job-applications-service';
import Input from '../form-components/input';
import Select from '../form-components/select';
import TelephoneInput from '../form-components/telephone-input';
import Modal from '../../components/modal';

import JobAppFormValidationService from './validation-helper';
import fieldHelper from './field-helper';
import fieldNames from './field-names';
import { getSubmitButtonText, getSubmitButtonClass } from './submit-btn-helper';
import { areMessagesDark, isApplyPage } from './form-style-helper';
import { httpResponses } from '../../constants/http-responses';

import styles from './job-application-form.module.scss';
import { useAppContext } from '../../providers/app-provider';
import { useAuth } from '../../providers/auth-provider';
import { useGaHelper } from '../../services/google-analytics-service';
import ApiErrorMessage from '../error-messages/api-error-message';
import { errorMessages } from '../../constants/error-messages';
import { urlParams } from '../../constants/url-params';
import PopupNotification from '../popup-notification';
import { CAPS_LOCK_WARNING } from '../../constants/misc-client-messges';
import formLocations from './form-locations';

const JobApplicationForm = ({ job, formLocation }) => {
  const currentLocation = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [professions, setProfessions] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [
    performPreliminaryDuplicateCheck,
    setPerformPreliminaryDuplicateCheck,
  ] = useState(true);
  const [possibleDuplicateEmail, setPossibleDuplicateEmail] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { createApplication } = useJobApplicationsApi();
  const {
    trackDupeApplicationAttempt,
    triggerApplicationTrackingEvent,
    trackSourceReferred,
    trackReferralEntered,
  } = useGaHelper();

  const formFields = fieldHelper.getFormFields(formLocation);

  const initialFieldValues = fieldHelper.getInitialFieldValues(formFields);

  const validate = JobAppFormValidationService.getValidatorFunc(
    formFields,
    specialties
  );

  const msgStyles = areMessagesDark(formLocation)
    ? styles.darkMsg
    : styles.lightMsg;

  const { setApplicantInfo, isCapsLockActive } = useAppContext();
  const { loggedIn, getLoginPathBasedOnTalentStackActiveFlag } = useAuth();

  const loginPath = getLoginPathBasedOnTalentStackActiveFlag();

  const getLoginLinkPath = () => {
    let path = loginPath;
    if (currentLocation.pathname.startsWith('/jobs')) {
      path += `?redirectTo=${currentLocation.pathname}`;
    }
    return path;
  };

  const generateLoginLink = () => {
    return (
      <Link
        to={getLoginLinkPath()}
        className={job && job.statusName === 'Closed' ? `` : `has-text-info`}
      >
        Sign&nbsp;in
      </Link>
    );
  };

  const formik = useFormik({
    initialValues: initialFieldValues,
    validate,
    onSubmit: values => {
      setIsLoading(true);
      triggerApplicationTrackingEvent(job, formLocation);
      if (values.referrer) {
        const page =
          formLocation === formLocations.HOME_PAGE
            ? 'Home Page'
            : 'Job Details';
        trackSourceReferred(page, values.email);
        trackReferralEntered(page, values.email, values.referrer);
      }
      createApplication(
        values,
        performPreliminaryDuplicateCheck,
        job,
        formLocation
      )
        .then(({ data }) => {
          const { profileId, isExistingUser } = data;
          setApplicantInfo({
            profileId: profileId,
            email: values.email,
          });
          if (isExistingUser) navigate(loginPath);
          else
            navigate(
              `/profile/expertise/?${urlParams.APPLICATION_SUCCESS}=true`
            );
        })
        .catch(err => {
          setIsLoading(false);
          if (err?.response?.status === httpResponses.CONFLICT_ERROR_CODE) {
            setPossibleDuplicateEmail(err.response.data.maskedDuplicateEmail);
            trackDupeApplicationAttempt(values.email);
            setShowConfirmationModal(true);
            return;
          }
          formik.setStatus({ apiError: true });
        });
    },
  });

  useEffect(() => {
    if (formik.values.profession) {
      formik.setFieldValue('specialty', '');
      setSpecialties([]);

      apiService
        .get('/api/specialties', {
          professionId: formik.values.profession,
        })
        .then(({ data }) => {
          setSpecialties(data);
        });
    }
  }, [formik.values.profession]);

  useEffect(() => {
    if (fieldHelper.isFieldUsed(fieldNames.PROFESSION, formFields)) {
      apiService.get('/api/professions').then(({ data }) => {
        setProfessions(data);
      });
    }
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {fieldHelper.isFieldUsed(fieldNames.FIRST_NAME, formFields) ? (
          <div className="field">
            <Input
              id="firstName"
              name="firstName"
              placeholder="First name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              errorMessage={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
              errorClass={msgStyles}
              className="is-rounded"
              shouldCheckForCapsLock={true}
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.LAST_NAME, formFields) ? (
          <div className="field">
            <Input
              id="lastName"
              name="lastName"
              placeholder="Last name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              errorMessage={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
              errorClass={msgStyles}
              className="is-rounded"
              shouldCheckForCapsLock={true}
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.EMAIL, formFields) ? (
          <div className="field">
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              errorMessage={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
              errorClass={msgStyles}
              className="is-rounded"
              shouldCheckForCapsLock={true}
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.PHONE_NUMBER, formFields) ? (
          <div className="field">
            <TelephoneInput
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              errorMessage={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? formik.errors.phoneNumber
                  : null
              }
              errorClass={msgStyles}
              className="is-rounded"
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.PROFESSION, formFields) ? (
          <div className="field">
            <Select
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.profession}
              placeholder="Your profession"
              selectProps={{
                id: 'profession',
                name: 'profession',
              }}
              options={professions.map(({ id, name }) => ({
                value: id,
                name: name,
              }))}
              errorMessage={
                formik.touched.profession && formik.errors.profession
                  ? formik.errors.profession
                  : null
              }
              errorClass={msgStyles}
              showDisabled
              rounded
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.SPECIALTY, formFields) &&
        formik.values.profession ? (
          <div className="field">
            <Select
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.specialty}
              placeholder="Your specialty"
              selectProps={{
                id: 'specialty',
                name: 'specialty',
              }}
              options={specialties.map(({ id, name }) => ({
                value: id,
                name: name,
              }))}
              errorMessage={
                formik.touched.specialty && formik.errors.specialty
                  ? formik.errors.specialty
                  : null
              }
              errorClass={msgStyles}
              showDisabled
              rounded
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.HOW_DID_YOU_FIND_US, formFields) ? (
          <div className="field">
            <Select
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.howDidYouFindUs}
              placeholder="How did you find us?"
              selectProps={{
                id: 'howDidYouFindUs',
                name: 'howDidYouFindUs',
              }}
              options={fieldHelper.getLeadSourceNames()}
              errorMessage={
                formik.touched.howDidYouFindUs && formik.errors.howDidYouFindUs
                  ? formik.errors.howDidYouFindUs
                  : null
              }
              errorClass={msgStyles}
              showDisabled
              rounded
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.REFERRER, formFields) &&
        formik.values.howDidYouFindUs === 'Referred by someone' ? (
          <div className="field">
            <Input
              id="referrer"
              name="referrer"
              placeholder="Referrer"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.referrer}
              errorMessage={
                formik.touched.referrer && formik.errors.referrer
                  ? formik.errors.referrer
                  : null
              }
              errorClass={msgStyles}
              className="is-rounded"
              shouldCheckForCapsLock={true}
            />
          </div>
        ) : null}

        {fieldHelper.isFieldUsed(fieldNames.OTHER, formFields) &&
        formik.values.howDidYouFindUs === 'Other' ? (
          <div className="field">
            <Input
              id="other"
              name="other"
              placeholder="Please specify"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.other}
              errorMessage={
                formik.touched.other && formik.errors.other
                  ? formik.errors.other
                  : null
              }
              errorClass={msgStyles}
              className="is-rounded"
              shouldCheckForCapsLock={true}
            />
          </div>
        ) : null}

        <Modal
          title={`Confirm your account`}
          isOpen={showConfirmationModal}
          message={`It looks like someone has already created an account using ${possibleDuplicateEmail}.  If this is you, please sign in instead.`}
          isCentered={true}
          confirmButtonText="Log in"
          confirmButtonModifierClass={`is-info`}
          messageModifierClass={`is-info`}
          showCancelButton={true}
          cancelButtonText={`Submit application`}
          showCancelIconButton={false}
          onConfirm={() => {
            navigate(getLoginLinkPath());
          }}
          onCancel={() => {
            setPerformPreliminaryDuplicateCheck(false);
            setShowConfirmationModal(false);
            formik.handleSubmit(formik.values);
          }}
          isSubmitting={isLoading}
          showModalCloseButton={false}
        />
        {formik.status && formik.status.apiError && (
          <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_SAVE} />
        )}

        <div
          className={`has-text-centered field ${styles.submitButtonWrapper}`}
        >
          <button
            type="submit"
            className={`button ${styles.submitButton} ${getSubmitButtonClass(
              job,
              formLocation
            )} ${isLoading ? 'is-loading disabled' : ''}
                `}
          >
            {getSubmitButtonText(formLocation)}
          </button>
          {!loggedIn && !isApplyPage(formLocation) && (
            <p className={`${styles.signInMsg}`}>
              <strong className={` ${msgStyles}  `}>
                Already have an account? {generateLoginLink()}
              </strong>
            </p>
          )}
        </div>
      </form>
      {isCapsLockActive && (
        <PopupNotification
          message={CAPS_LOCK_WARNING}
          duration="persistent"
          notificationStyleClass="is-danger"
          showCloseIcon={false}
        />
      )}
    </>
  );
};

export default JobApplicationForm;
