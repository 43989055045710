const LOCALE = 'en-US';

export const getFormattedLocation = (city, state, useAbbreviation = true) => {
  if (city === null) {
    return state.name;
  }

  const stateText = useAbbreviation ? state.abbreviation : state.name;

  return `${city}, ${stateText}`;
};

export const getFormattedDate = date => {
  let dateArr = date.slice(0, 10).split('-');
  let formatted = dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
  return formatted;
};

export const getFormattedDateTime = dateTime => {
  if (dateTime === null) return '';

  const date = new Date(dateTime);
  return date.toLocaleString(LOCALE);
};

export const convertMonthsToMonthsAndYears = totalMonths => {
  if (totalMonths == null) {
    return {
      years: '',
      months: '',
    };
  }

  const MONTHS_IN_YEAR = 12;

  return {
    years: Math.floor(totalMonths / MONTHS_IN_YEAR).toString(),
    months: (totalMonths % MONTHS_IN_YEAR).toString(),
  };
};

export const getDateAsMMDD = date => {
  let d = new Date(date);
  return d.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
  });
};

export const getFutureDate = (oldDate, daysToAdd) => {
  if (!oldDate || !daysToAdd || daysToAdd === 0) return '';
  const date = new Date(oldDate);
  let newDate = date.setDate(date.getDate() + daysToAdd);
  return formatDate(newDate);
};

export const formatDate = date => {
  return new Date(date).toLocaleDateString(LOCALE, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const getJsDateAsString = date => {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getNext12Months = (startDate = new Date()) => {
  let year = startDate.getFullYear();
  let month = startDate.getMonth() + 1;

  const dates = [];
  for (let i = 0; i < 12; i++) {
    if (month === 13) {
      month = 1;
      year++;
    }

    dates.push(new Date(`${month}/1/${year}`));
    month++;
  }
  return dates;
};
