import fieldNames from './field-names';

const preSetFieldLists = {
  homePage: [
    fieldNames.FIRST_NAME,
    fieldNames.LAST_NAME,
    fieldNames.EMAIL,
    fieldNames.PHONE_NUMBER,
    fieldNames.PROFESSION,
    fieldNames.SPECIALTY,
    fieldNames.HOW_DID_YOU_FIND_US,
    fieldNames.REFERRER,
    fieldNames.OTHER,
  ],
  jobDetails: [
    fieldNames.FIRST_NAME,
    fieldNames.LAST_NAME,
    fieldNames.EMAIL,
    fieldNames.PHONE_NUMBER,
    fieldNames.HOW_DID_YOU_FIND_US,
    fieldNames.REFERRER,
    fieldNames.OTHER,
  ],
  DEFAULT: [
    fieldNames.FIRST_NAME,
    fieldNames.LAST_NAME,
    fieldNames.EMAIL,
    fieldNames.PHONE_NUMBER,
  ],
};

export default preSetFieldLists;
